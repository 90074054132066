import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  loader: false,
  user: null,
  token: null,
  resetPassword: false,
  changePassword: {
    isLoading: false,
    codeValid: false,
    success: false,
  },
  changePasswordAuthenticated: {
    isLoading: false,
    success: false,
  },
  successRegister: false,
  resendEmailSuccess: false,
  verifyEmailSuccess: false,
  redirectPath: null,
  idProject: null,
  lastId: null,
  lastPage: null,
  isCompleted: null,
  nudgeStatus: null,
  locationUser: {
    isLoading: false,
    success: false,
    country: null,
  },
  isPhoneVerified: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login(state) {
      state.loader = true;
    },
    loginSuccess(state, action) {
      state.loader = false;
      state.isAuthenticated = !!localStorage.getItem('token_fairatmos');
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.redirectPath = action.payload.redirect;
    },
    loginFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
    getLastPage(state, action) {
      state.loader = true;
      state.idProject = action.payload;
    },
    getLastPageSuccess(state, action) {
      state.loader = false;
      state.idProject = action.payload.idProject;
      state.lastId = action.payload.id;
      state.lastPage = action.payload.page;
      state.isCompleted = action.payload.isCompleted;
      state.nudgeStatus = 'Success';
    },
    getLastPageFailure(state) {
      state.loader = false;
      state.nudgeStatus = 'Fail';
    },
    getLocationUser(state, action) {},
    getLocationUserLoading(state, action) {
      state.locationUser.isLoading = action.payload;
    },
    getLocationUserSuccess(state, action) {
      state.locationUser.isLoading = false;
      state.locationUser.success = true;
      state.locationUser.country = action.payload.country;
    },
    getLocationUserFailure(state) {
      state.locationUser.isLoading = false;
      state.locationUser.success = false;
    },
    logout(state) {
      state.loader = true;
    },
    register(state) {
      state.loader = true;
      state.successRegister = false;
    },
    registerSuccess(state, action) {
      state.loader = false;
      state.successRegister = true;
    },
    registerFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.successRegister = false;
    },
    resetPassword(state) {
      state.loader = true;
      state.resetPassword = false;
    },
    resetPasswordSuccess(state) {
      state.loader = false;
      state.resetPassword = true;
    },
    changePassword(state, action) {
      state.changePassword = { ...state.changePassword, ...action.payload };
    },
    updatePassword(state, action) {
      state.changePassword = {
        ...state.changePassword,
        ...action.payload.success,
      };
    },
    updatePasswordAuthenticated(state, action) {
      state.changePassword = {
        ...state.changePasswordAuthenticated,
        isLoading: true,
        success: false,
      };
    },
    updatePasswordAuthenticatedSuccess(state, action) {
      state.changePassword = {
        ...state.changePasswordAuthenticated,
        isLoading: false,
        ...action.payload.success,
      };
    },
    updatePasswordAuthenticatedFailed(state, action) {
      state.changePassword = {
        ...state.changePasswordAuthenticated,
        isLoading: false,
        success: false,
      };
    },
    updateAuthBasedOnProfile(state, action) {
      state.user = {
        ...state.user,
        displayName: action.payload.name,
      };
    },
    resendEmail(state) {
      state.loader = true;
    },
    resendEmailResponse(state, action) {
      state.loader = false;
      state.resendEmailSuccess = action.payload;
    },
    verifyEmail(state) {
      state.loader = true;
    },
    verifyEmailResponse(state, action) {
      state.loader = false;
      state.verifyEmailSuccess = action.payload;
    },
    setLoading(state, action) {
      state.loader = action.payload || true;
    },
  },
});

export const {
  changePassword,
  login,
  loginSuccess,
  loginFailure,
  getLastPage,
  getLastPageSuccess,
  getLastPageFailure,
  getLocationUser,
  getLocationUserLoading,
  getLocationUserSuccess,
  getLocationUserFailure,
  logout,
  register,
  registerSuccess,
  registerFailure,
  resendEmail,
  resendEmailResponse,
  resetPassword,
  resetPasswordSuccess,
  setLoading,
  updatePassword,
  updatePasswordAuthenticated,
  updatePasswordAuthenticatedSuccess,
  updatePasswordAuthenticatedFailed,
  verifyEmail,
  verifyEmailResponse,
  setRedirectPath,
  updateAuthBasedOnProfile,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
