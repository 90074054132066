import { notification } from 'antd';
import { getRequest, postRequest } from 'app/axiosClient';
import { FirebaseError } from 'firebase/app';
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  auth,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from '../../firebase';
import {
  changePassword,
  getLastPage,
  getLastPageFailure,
  getLastPageSuccess,
  getLocationUser,
  getLocationUserFailure,
  getLocationUserLoading,
  getLocationUserSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  register,
  registerFailure,
  registerSuccess,
  resendEmail,
  resendEmailResponse,
  resetPassword,
  resetPasswordSuccess,
  setLoading,
  updatePassword,
  updatePasswordAuthenticated,
  updatePasswordAuthenticatedFailed,
  updatePasswordAuthenticatedSuccess,
  verifyEmail,
  verifyEmailResponse,
} from './authenticationSlice';

function* loginAPI(action) {
  try {
    const response = yield call(() =>
      signInWithEmailAndPassword(
        auth,
        action.payload.data.email,
        action.payload.data.password,
      ),
    );
    const customAttrs = JSON.parse(
      response.user.reloadUserInfo.customAttributes,
    );
    const user = {
      email: response.user.email,
      displayName: response.user.displayName,
      photoURL: response.user.photoURL,
      userId: customAttrs.fairatmos_user_id,
      roles: customAttrs.roles,
    };
    yield put(
      loginSuccess({
        token: response.user.accessToken,
        user,
        redirect: action.payload.redirect,
      }),
    );
    window.localStorage.setItem('user_fairatmos', JSON.stringify(user));
    window.localStorage.setItem('token_fairatmos', response.user.accessToken);
    window.localStorage.setItem(
      'redirect',
      JSON.stringify(action.payload.redirect),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_login'),
      description: i18n.t('failed_login_msg'),
    });
    yield put(loginFailure());
    setTimeout(() => {
      if (action.payload.redirect) {
        window.location.href = action.payload.redirect;
      }
    }, 1000);
  }
}

function* resetPasswordAPI(action) {
  try {
    yield call(() => sendPasswordResetEmail(auth, action.payload.email));
    notification['success']({
      message: i18n.t('success_reset_pass'),
      description: i18n.t('check_email_after_reset'),
    });
    yield put(resetPasswordSuccess());
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_reset_pass'),
      description: errorMsg(e.message),
    });
    yield put(resetPasswordSuccess());
  }
}

function* logoutAPI() {
  try {
    yield call(() =>
      postRequest('user/sign-out').then((res) => {
        if (res.data.success) {
          window.localStorage.clear();
        }
      }),
    );
  } catch (error) {}
  yield signOut(auth)
    .then(() => {
      window.localStorage.clear();
    })
    .catch((error) => {
      notification['error']({
        message: i18n.t('failed_logout'),
      });
    });

  yield put(loginFailure());
}

const errorMsg = (error) => {
  switch (error) {
    case 'EMAIL_EXISTS':
      return i18n.t('error_email_exists');
    case 'EMAIL_NOT_FOUND':
      return i18n.t('error_email_not_found');
    case 'INVALID_PASSWORD':
      return i18n.t('error_invalid_password');
    case 'WEAK_PASSWORD':
      return i18n.t('error_weak_password');
    case 'EXPIRED_OOB_CODE':
      return i18n.t('error_expired_oob_code');

    default:
      return error;
  }
};

function* registerAPI(action) {
  try {
    let response;
    let request;
    let payload = action.payload;
    if (action.payload.isExpert) {
      request = 'experts/register';
    } else {
      request = 'user/register';
    }
    delete payload['isExpert'];
    response = yield call(() => postRequest(request, payload));
    yield put(
      registerSuccess({
        user: response.data,
      }),
    );
    notification['success']({
      message: i18n.t('succeed_create_account'),
    });
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_create_account'),
      description: i18n.t('desc_failed_create_account'),
    });
    yield put(registerFailure());
  }
}

function* changePasswordAPI(action) {
  put(
    changePassword({
      isLoading: true,
    }),
  );
  try {
    yield call(() => {
      verifyPasswordResetCode(auth, action.payload.actionCode)
        .then(() => {
          put(
            changePassword({
              isLoading: false,
              codeValid: true,
            }),
          );
          const newPassword = action.payload.password;
          confirmPasswordReset(auth, action.payload.actionCode, newPassword)
            .then(() => {
              notification['success']({
                message: i18n.t('change_password_success'),
              });
              put(
                changePassword({
                  isLoading: false,
                  success: true,
                }),
              );
              // TODO: Display a link back to the app, or sign-in the user directly
              // if the page belongs to the same domain as the app:
              // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            })
            .catch((error) => {
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
              if (error instanceof FirebaseError) {
                notification['error']({
                  message: i18n.t('change_password_failed'),
                });
              }
              put(
                changePassword({
                  isLoading: false,
                }),
              );
            });
        })
        .catch((error) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          if (error instanceof FirebaseError) {
            notification['error']({
              message: i18n.t('change_password_failed'),
            });
          }
          put(
            changePassword({
              isLoading: false,
            }),
          );
        });
    });
    put(
      changePassword({
        isLoading: false,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('change_password_failed'),
      description: errorMsg(e.message),
    });
    yield put(resetPasswordSuccess());
  }
}

function* updatePasswordAPI(action) {
  put(
    updatePassword({
      isLoading: true,
    }),
  );
  try {
    yield call(() => postRequest('user/change-password', action.payload));
    notification['success']({
      message: i18n.t('change_password_success'),
    });
    put(
      updatePassword({
        isLoading: false,
        success: true,
      }),
    );
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  } catch (e) {
    notification['error']({
      message: i18n.t('change_password_failed'),
    });
    put(
      updatePassword({
        isLoading: false,
      }),
    );
  }
}

function* updatePasswordAuthenticatedAPI(action) {
  try {
    yield call(() =>
      postRequest('user/change-password-validation', action.payload),
    );
    notification['success']({
      message: i18n.t('change_password_success'),
    });
    yield put(
      updatePasswordAuthenticatedSuccess({
        isLoading: false,
        success: true,
      }),
    );
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  } catch (e) {
    notification['error']({
      message: i18n.t('update_password_failed'),
    });
    yield put(updatePasswordAuthenticatedFailed());
  }
}

function* resendEmailAPI(action) {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() =>
      postRequest('user/resend-verification', action.payload, true),
    );

    yield put(resendEmailResponse(!res.error));
    notification['success']({
      message: i18n.t('send_email_success'),
    });
  } catch (e) {
    notification['error']({
      message: i18n.t('send_email_failed'),
    });
    yield put(resendEmailResponse(false));
  }
}

function* verifyEmailAPI(action) {
  yield put(setLoading(true));
  try {
    yield call(() => postRequest('user/verify', action.payload, true));

    yield put(verifyEmailResponse(true));
    notification['success']({
      message: i18n.t('verification_success'),
    });
  } catch (e) {
    notification['error']({
      message: i18n.t('verification_failed'),
    });
    yield put(verifyEmailResponse(false));
  }
}

function* getLastPageAPI(action) {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload}/last-page/`, true),
    );
    yield put(
      getLastPageSuccess({
        id: res.data.id,
        page: res.data.page,
        idProject: res.data.project_id,
        isCompleted: res.data.is_completed,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('error_get_project_last_page'),
    });
    yield put(getLastPageFailure());
  }
}

function* getLocationUserAPI(action) {
  yield put(getLocationUserLoading(true));
  try {
    let res;
    res = yield call(() => getRequest('simple-pdd/geo-location', true));
    yield put(
      getLocationUserSuccess({
        country: res.data.country,
      }),
    );
  } catch (e) {
    yield put(getLocationUserFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(changePassword, changePasswordAPI),
    takeLatest(login, loginAPI),
    takeLatest(logout, logoutAPI),
    takeLatest(register, registerAPI),
    takeLatest(resendEmail, resendEmailAPI),
    takeLatest(resetPassword, resetPasswordAPI),
    takeLatest(updatePassword, updatePasswordAPI),
    takeLatest(updatePasswordAuthenticated, updatePasswordAuthenticatedAPI),
    takeLatest(verifyEmail, verifyEmailAPI),
    takeLatest(getLastPage, getLastPageAPI),
    takeLatest(getLocationUser, getLocationUserAPI),
  ]);
}
