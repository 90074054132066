const GLOBAL_ERROR = [
  'error_get_project_reference',
  'error_get_project_last_page',
  'error_no_access_data',
  'error_param',
  'error_old_password_invalid',
];

const JARVIS_ERROR = ['error_jarvis_cant_process'];

const OTP_ERROR = ['error_phone_not_verified'];

export { GLOBAL_ERROR, JARVIS_ERROR, OTP_ERROR };
