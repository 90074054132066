import { all } from 'redux-saga/effects';
import authenticateSaga from 'features/Auth/authenticateAPI';
import eligibilitySaga from 'features/Eligibility/eligibilityAPI';
import projectSaga from 'features/Project/projectAPI';
import expertSaga from 'features/Expert/expertAPI';
import planSaga from 'features/Plan/planAPI';
import profileSaga from 'features/Profile/profileAPI';
import createProjectSaga from 'features/CreateProject/reducers/CreateProjectAPI';
import resultSaga from 'features/Result/resultAPI';

// Here you can include all the saga which you write for components
export default function* rootSaga() {
  yield all([
    authenticateSaga(),
    eligibilitySaga(),
    planSaga(),
    projectSaga(),
    expertSaga(),
    profileSaga(),
    createProjectSaga(),
    resultSaga(),
  ]);
}
