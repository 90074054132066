import axios from 'axios';
import i18n from 'i18next';
const { version } = require('../../package.json');

const axiosClient = axios.create();

function setAxiosHeader(useAuth = true) {
  axiosClient.defaults.timeout = 1000 * 60 * 4; // 4 minutes
  axiosClient.defaults.baseURL = process.env.REACT_APP_HOST_URL;
  axiosClient.defaults.withCredentials = false;
  const headerParams = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': `${i18n.language}`,
    'X-Client-Version': `atmoscheck/v${version}`,
  };
  if (useAuth) {
    headerParams['Authorization'] = `Bearer ${window.localStorage.getItem(
      'token_fairatmos',
    )}`;
  }
  axiosClient.defaults.headers = headerParams;
}

// Function to handle timeouts
function handleTimeout(timeout) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, timeout);
  });
}

// Function to make a request with timeout handling
function requestWithTimeout(config) {
  return Promise.race([axiosClient(config), handleTimeout(config.timeout)]);
}

export function getRequest(URL, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .get(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        window.localStorage.clear();
        window.location.href = '/sign-in';
      }
      if (error.response.data.error === 'error_not_verified') {
        postRequest('user/sign-out').then((res) => {
          if (res.data.success) {
            window.location.href = `/check-email?email=${
              JSON.parse(localStorage.getItem('user_fairatmos') || '{}')?.email
            }`;
            window.localStorage.clear();
          }
        });
      }
      return Promise.reject(error.response);
    });
}

export function getRequestPlain(URL) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .get(URL)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function postRequest(URL, payload, useAuth = true) {
  setAxiosHeader(useAuth);
  return requestWithTimeout({
    url: `/${URL}`,
    method: 'post',
    data: payload,
    timeout: axiosClient.defaults.timeout,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear();
        window.location.href = '/sign-in';
      }
      return Promise.reject(error.response);
    });
}

export function postRequestPlain(URL, payload) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .post(URL, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function putRequest(URL, payload, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .put(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}
export function putRequestPlain(URL, payload) {
  setAxiosHeader();
  axiosClient.defaults.baseURL = null;
  return axiosClient
    .put(URL, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function patchRequest(URL, payload, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .patch(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}

export function deleteRequest(URL, useAuth = true) {
  setAxiosHeader(useAuth);
  return axiosClient
    .delete(`/${URL}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));
}
