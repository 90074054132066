import { notification } from 'antd';
import { deleteRequest, getRequest, patchRequest } from 'app/axiosClient';
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteProject,
  getManageable,
  getManageableLoading,
  getManageableSuccess,
  lockAccount,
  projectList,
  projectListEmpty,
  projectListFailed,
  projectListLoading,
  projectListSuccess,
  unlockAccount,
  unlockAccountFailed,
  unlockAccountLoading,
  unlockAccountSuccess,
} from './projectSlice';

function* projectListAPI(action) {
  yield put(
    projectListLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/projects${
          action.payload ? `?${action.payload}&size=10` : ''
        }`,
      ),
    );

    if (res?.status === 204) {
      yield put(
        projectListEmpty({
          projectEmpty: true,
        }),
      );
    } else if (res?.status === 200) {
      yield put(
        projectListSuccess({
          projectList: res?.data,
          projectEmpty: false,
          isManagableData: res?.data && res?.data?.is_manageable_data,
        }),
      );
    }
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_project_list'),
    });
    yield put(projectListFailed());
  }
}

function* getManageableAPI(action) {
  yield put(
    getManageableLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('simple-pdd/projects'));

    if (res?.status === 200) {
      yield put(
        getManageableSuccess({
          isManagableData: res?.data?.is_manageable_data,
        }),
      );
    }
  } catch (e) {}
}

function* unlockAccountAPI(action) {
  yield put(unlockAccountLoading());
  try {
    yield call(() =>
      patchRequest('simple-pdd/unlock/projects', action.payload),
    );
    notification['success']({
      message: i18n.t('verify_success'),
    });
    yield put(unlockAccountSuccess());
  } catch (e) {
    notification['error']({
      message: i18n.t('verify_failed'),
    });
    yield put(unlockAccountFailed());
  }
}

function* lockAccountAPI(action) {
  yield put(unlockAccountLoading());
  try {
    yield call(() => patchRequest('simple-pdd/lock/projects', action.payload));
    yield put(unlockAccountSuccess());
  } catch (e) {
    yield put(unlockAccountFailed());
  }
}

function* deleteProjectAPI(action) {
  try {
    let res = yield call(() =>
      deleteRequest(`simple-pdd/projects/${action.payload}`),
    );
    if (res.status === 200) {
      notification['success']({
        message: i18n.t('delete_project_success'),
      });
    }
  } catch (e) {
    notification['error']({
      message: e?.data?.message || i18n.t('delete_project_failed'),
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(projectList, projectListAPI),
    takeLatest(getManageable, getManageableAPI),
    takeLatest(unlockAccount, unlockAccountAPI),
    takeLatest(lockAccount, lockAccountAPI),
    takeLatest(deleteProject, deleteProjectAPI),
  ]);
}
