import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'app/store';
import './i18n.js';
import * as Sentry from '@sentry/react';
const { version } = require('../package.json');

if (process.env['REACT_APP_SENTRY_DSN'] !== '') {
  Sentry.init({
    release: `atmoscheck:${process.env['REACT_APP_ENV']}@${version}`,
    dsn: process.env['REACT_APP_SENTRY_DSN'],
    environment: process.env['REACT_APP_ENV'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <React.StrictMode>
    {/*Redux Provider is included access the store values from anywhere inside the child components.*/}
    <Provider store={store()}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
