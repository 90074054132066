const userData = JSON.parse(window.localStorage.getItem('user_fairatmos'));
let homePath;
if (userData && userData.roles) {
  if (userData.roles[0] === 'user') {
    homePath = '/projects';
  }
}
if (userData && userData.roles) {
  if (userData.roles[0] === 'expert') {
    homePath = '/profile/edit';
  }
}

const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem('token_fairatmos')}`,
  },
  MAPS_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  PAGINATION: {
    pageSize: 1,
  },
  LANGUAGES: [
    {
      key: 'id',
      value: 'id',
      label: 'Bahasa',
    },
    {
      key: 'en',
      value: 'en',
      label: 'English',
    },
  ],
  HOME_PATH: homePath ? homePath : '/projects',
  LOADER_ID: `${process.env.REACT_APP_BUCKET_URL}/articles/atmoscheck-loading-id.gif`,
  LOADER_EN: `${process.env.REACT_APP_BUCKET_URL}/articles/atmoscheck-loading-en.gif`,
  SYARAT_KETENTUAN: `${process.env.REACT_APP_BUCKET_URL}/file/SYARATDANKETENTUAN.pdf`,
  VERRA_LOGO: `${process.env.REACT_APP_BUCKET_URL}/images/verra-logo.webp`,
  SRN_LOGO: `${process.env.REACT_APP_BUCKET_URL}/images/srn-logo.webp`,
  QUESTION_SIGN: `${process.env.REACT_APP_BUCKET_URL}/images/question-sign.webp`,
  THREE_DOTS: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/three-dots.svg`,
  TRASH_RED: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/trash-red.svg`,
  TRASH_GREEN: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/trash-green.svg`,
  ARROW_RIGHT_CIRCLE: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/arrow-right-circle.svg`,
  EYE_WHITE: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/eye-white.svg`,
  SHP_ICON: `${process.env.REACT_APP_BUCKET_URL}/articles/file_shp.svg`,
  SHX_ICON: `${process.env.REACT_APP_BUCKET_URL}/articles/file_shx.svg`,
  PRJ_ICON: `${process.env.REACT_APP_BUCKET_URL}/articles/file_prj.svg`,
  DBF_ICON: `${process.env.REACT_APP_BUCKET_URL}/articles/file_dbf.svg`,
  ISO: `${process.env.REACT_APP_BUCKET_URL}/articles/iso-shield.svg`,
  BROCOLI_GREEN: `${process.env.REACT_APP_BUCKET_URL}/articles/brocoli-cluster-green.png`,
  CIRCLE_BACK_BTN: `${process.env.REACT_APP_BUCKET_URL}/articles/circle-back-button.svg`,
  FA_LOGO_WHITE: `${process.env.REACT_APP_BUCKET_URL}/articles/fa-logo-white.svg`,
  PRIVACY_POLICY: `${process.env.REACT_APP_BUCKET_URL}/file/privacypolicy.pdf`,
  TNC: `${process.env.REACT_APP_BUCKET_URL}/file/termsandcondition.pdf`,
  THANKYOU: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/thankyou.svg`,
  REFRESH_OLD: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/refresh-old.svg`,
  TREE_LEFT: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/tree-left.svg`,
  DATA_ISSUE: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/data-issue.svg`,
  SHAPEFILE_INFO: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/shapefile-info.svg`,
  SUPPORT_SHP_1: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-1.svg`,
  SUPPORT_SHP_2: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-2.svg`,
  SUPPORT_SHP_3: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-3.svg`,
  SUPPORT_SHP_4: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-4.svg`,
  SUPPORT_SHP_5: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-5.svg`,
  SUPPORT_SHP_6: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/support-shp-6.svg`,
  FILTER: `${process.env.REACT_APP_BUCKET_URL}/atmoscheck/filter.svg`,
};

export default constants;
