import { notification } from 'antd';
import { getRequest, postRequest } from 'app/axiosClient';
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  postVCUCalculation,
  postVCUCalculationLoading,
  postVCUCalculationSuccess,
  postVCUCalculationFailed,
  projectReference,
  projectReferenceFailed,
  projectReferenceSuccess,
  setContactData,
  setContactDataFailed,
  setContactDataLoading,
  setContactDataSuccess,
  waitMapReady,
  waitMapReadyLoading,
  waitMapReadySuccess,
  getResultVCULoading,
  getResultVCUSuccess,
  getResultVCUFailed,
  getResultVCU,
  getInputVCULoading,
  getInputVCUSuccess,
  getInputVCUFailed,
  getInputVCU,
  changeProjectStatus,
  getLinkDownloadCSVLoading,
  getLinkDownloadCSVSuccess,
  getLinkDownloadCSVFailed,
  getLinkDownloadCSV,
  changeResultReady,
  trackDownloadPDF,
} from './resultSlice';
import { isExistProp } from 'helper';

function* getProjectReferenceAPI(action) {
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    if (res.status === 204) {
      yield put(
        changeResultReady({
          resultReady: false,
          loading: true,
        }),
      );
    }

    if (res.status === 200) {
      yield put(
        projectReferenceSuccess({
          result: res.data,
          isManagableData: res?.data?.is_manageable_data,
          isOldProject: res?.data?.is_old_project,
          isDataIssue: res.data && isExistProp(res?.data?.email),
        }),
      );
    }
  } catch (e) {
    notification['error']({
      message: e?.data?.message || i18n.t('error_get_project_reference'),
    });
    yield put(projectReferenceFailed());
  }
}

function* getMapReadyAPI(action) {
  yield put(waitMapReadyLoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    yield put(
      waitMapReadySuccess({
        statusLinkMap1: res?.data?.summary?.reports?.project_area[0]?.map_ready,
        linkMap1: res?.data?.summary?.reports?.project_area[0]?.map_user,
        mapLegend1: res?.data?.summary?.reports?.project_area[0]?.map_legend,
        statusLinkMap2: res?.data?.summary?.reports?.project_area[1]?.map_ready,
        linkMap2: res?.data?.summary?.reports?.project_area[1]?.map_user,
        mapLegend2: res?.data?.summary?.reports?.project_area[1]?.map_legend,
        statusInternalMap:
          res?.data?.is_manageable_data === true
            ? res?.data?.summary?.additional_data?.maps[0]?.map_ready
            : false,
        internalMap:
          res?.data?.is_manageable_data === true
            ? res?.data?.summary?.additional_data?.maps[0]?.map_user
            : null,
      }),
    );
  } catch (e) {}
}

function* getResultVCUAPI(action) {
  yield put(getResultVCULoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.id}/result-prefs-premium`),
    );

    yield put(
      getResultVCUSuccess({
        data: res.data,
        statusCode: res.status,
      }),
    );
  } catch (e) {
    yield put(getResultVCUFailed());
  }
}

function* getInputVCUAPI(action) {
  yield put(getInputVCULoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.id}/create-prefs-premium`),
    );

    yield put(getInputVCUSuccess(res?.data?.data));
  } catch (e) {
    yield put(getInputVCUFailed());
  }
}

function* getLinkDownloadCSVAPI(action) {
  yield put(getLinkDownloadCSVLoading());
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.id}/download-prefs-premium`),
    );
    if (res.status === 204 || typeof res?.data?.url !== 'string') {
      notification['warning']({
        message: i18n.t('notif_download_title'),
        description: i18n.t('notif_download_desc'),
      });
    } else {
      yield put(getLinkDownloadCSVSuccess(res.data?.url));
    }
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_download_title'),
    });
    yield put(getLinkDownloadCSVFailed());
  }
}

function* setContactDataAPI(action) {
  yield put(setContactDataLoading());
  try {
    yield call(() =>
      postRequest('simple-pdd/set-preferred-contact', action.payload),
    );

    yield put(setContactDataSuccess());
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_confirm_contact'),
    });
    yield put(setContactDataFailed());
  }
}

function* postVCUCalculationAPI(action) {
  yield put(postVCUCalculationLoading());
  try {
    let res;
    res = yield call(() =>
      postRequest(
        `simple-pdd/${action.payload.id}/create-prefs-premium`,
        action.payload.body,
      ),
    );
    yield put(postVCUCalculationSuccess(res?.data?.data));
  } catch (e) {
    notification['error']({
      message: i18n.t('form_submit_failed'),
    });
    yield put(postVCUCalculationFailed());
  }
}

function* changeProjectStatusAPI(action) {
  try {
    yield call(() =>
      postRequest(
        `simple-pdd/${action.payload.id}/change-prefs-status`,
        action.payload.body,
      ),
    );
    notification['success']({
      message: i18n.t('change_status_success'),
    });
  } catch (e) {
    notification['error']({
      message: i18n.t('change_status_failed'),
    });
  }
}

function* trackDownloadPDFAPI(action) {
  try {
    yield call(() =>
      postRequest(`simple-pdd/${action.payload}/track-download-project-pdf`),
    );
    return true;
  } catch (e) {
    return false;
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(projectReference, getProjectReferenceAPI),
    takeLatest(setContactData, setContactDataAPI),
    takeLatest(waitMapReady, getMapReadyAPI),
    takeLatest(postVCUCalculation, postVCUCalculationAPI),
    takeLatest(getResultVCU, getResultVCUAPI),
    takeLatest(getInputVCU, getInputVCUAPI),
    takeLatest(changeProjectStatus, changeProjectStatusAPI),
    takeLatest(getLinkDownloadCSV, getLinkDownloadCSVAPI),
    takeLatest(trackDownloadPDF, trackDownloadPDFAPI),
  ]);
}
