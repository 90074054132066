import authentication from 'features/Auth/authenticationSlice';
import eligibility from 'features/Eligibility/eligibilitySlice';
import project from 'features/Project/projectSlice';
import plan from 'features/Plan/planSlice';
import expert from 'features/Expert/expertSlice';
import profile from 'features/Profile/profileSlice';
import createProject from 'features/CreateProject/reducers/CreateProjectSlice';
import result from 'features/Result/resultSlice';

//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  eligibility,
  project,
  plan,
  expert,
  profile,
  createProject,
  result,
};

export default rootReducer;
