import moment from 'moment';

export const formatCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat(
    currency === 'IDR' ? 'id-ID' : 'en-US',
    {
      style: 'currency',
      currency: currency,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    },
  );

  return formatter.format(value);
};

export const middleEllipsis = (str) => {
  let filename = '';
  if (str) {
    if (str.length > 15) {
      filename = `${str.substr(0, 8)}...${str.substr(
        str.length - 10,
        str.length,
      )}`;
    } else {
      filename = str;
    }
  }
  return filename;
};

export const displayStatusProject = (status) => {
  switch (status) {
    case 'Completed':
      return 'Lengkap';
    case 'In progress':
      return 'Sedang berlangsung';
    default:
      break;
  }
};

export const displayResultProject = (result, t) => {
  switch (result) {
    case 'Eligibility Yes':
      return t('table_approval_success');
    case 'Eligibility No':
      return t('table_approval_fail');
    case 'Processing':
      return t('table_approval_process');
    case 'In progress':
      return 'Draft';
    case 'Data Issue':
      return t('table_data_issue');
    default:
      break;
  }
};

export const colorBadgeResult = (is_eligible) => {
  let color;
  if (is_eligible === 'Eligibility Yes') {
    color = '#F0F4F2';
  } else if (is_eligible === 'In progress') {
    color = '#f0f0f0';
  } else if (is_eligible === 'Processing') {
    color = '#FFF9F2';
  } else if (is_eligible === 'Data Issue') {
    color = '#FFF9F2';
  } else {
    color = '#FCEAE9';
  }
  return color;
};

export const fontColorBadgeResult = (is_eligible) => {
  let fontColor;
  if (is_eligible === 'Eligibility Yes') {
    fontColor = '#367A59';
  } else if (is_eligible === 'In progress') {
    fontColor = '#595959';
  } else if (is_eligible === 'Processing') {
    fontColor = '#AB5E17';
  } else if (is_eligible === 'Data Issue') {
    fontColor = '#E3842B';
  } else {
    fontColor = '#FA6161';
  }
  return fontColor;
};

export const separateAllButLast = (str) => {
  let last = str.lastIndexOf(',');
  let butLast = str.substring(0, last).replace(/,/g, ', ');
  let res = butLast + str.substring(last).replace(/,/g, ' atau ');
  return res;
};

export const countDuplicate = (arr) => {
  let counts = {};
  arr.forEach((value) => {
    counts[value] = (counts[value] || 0) + 1;
  });
  return counts;
};

export const formatFileSize = (bytes, decimals = 2) => {
  if (!+bytes) return '';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const restructuredList = (data) => {
  let list =
    data &&
    data?.data?.map((el) => ({
      value: el.id,
      label: el.name,
    }));
  return list;
};

export const disableDateRanges = (
  range = { startDate: false, endDate: false },
) => {
  const { startDate, endDate } = range;
  return function disabledDate(current) {
    let startCheck = true;
    let endCheck = true;
    if (startDate) {
      startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
    }
    if (endDate) {
      endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
    }
    return (startDate && startCheck) || (endDate && endCheck);
  };
};

export const handleLongText = (text, maxLength) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

export const MaskingPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/(\+\d{2})(\d{2})(\d+)(\d{3})/, '$1$2XXXXXX$4');

export const handleLongTextWithoutElipsis = (text, maxLength) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}` : text;

// specific for draw polygon map formatting
export const numberWithSeparator = (x, separator) =>
  x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);

// general format number
export const formatNumber = (x) => x.toLocaleString('en-US');

// replace coma to get plain data
export const commatoPlain = (str) => {
  if (str) {
    let replaceComa = str.replace(/,/g, '');
    return parseInt(replaceComa);
  }
};

export const sortDesc = (data) =>
  data.sort((a, b) => (a.origin < b.origin ? 1 : b.origin < a.origin ? -1 : 0));

export const getTextContentOnly = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const walker = document.createTreeWalker(
    doc.body,
    NodeFilter.SHOW_TEXT,
    null,
    false,
  );
  const texts = [];
  let node;
  while ((node = walker.nextNode())) {
    texts.push(node.nodeValue);
  }
  return texts;
};

export const hasNullOrEmptyValue = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === '') {
      return true;
    }
  }
  return false;
};

export const handleDisableSwitch = (key) => {
  if (key && Object.keys(key).length > 0) {
    return false;
  }
  return true;
};

export const isExistProp = (key) => {
  if (key && Object.keys(key).length > 0) {
    return true;
  }
  return false;
};

export const determineByGrade = (grade) => {
  let result = {};
  switch (grade) {
    case 'low':
      result.bgImage = 'URL("/poor-card.svg")';
      result.color = '#fa6161';
      break;
    case 'medium':
      result.bgImage = 'URL("/okay-card.svg")';
      result.color = '#f6976f';
      break;
    case 'high':
      result.bgImage = 'URL("/good-card.svg")';
      result.color = '#384e40';
      break;
    default:
      break;
  }
  return result;
};

export const displayImg = (criteria, grade) => {
  let source;
  switch (criteria) {
    case 'climate':
      source = `/climate-${grade}.svg`;
      break;
    case 'tenurial_conflict':
      source = `/tenurial-${grade}.svg`;
      break;
    case 'finance':
      source = `/finance-${grade}.svg`;
      break;
    case 'co_benefit':
      source = `/benefit-${grade}.svg`;
      break;
    case 'land_use_rights':
      source = `/land-use-${grade}.svg`;
      break;
    default:
      break;
  }
  return source;
};

export const determineCard = (grade) => {
  switch (grade) {
    case 'low':
      return 'URL("/poor-card.svg")';
    case 'medium':
      return 'URL("/okay-card.svg")';
    case 'high':
      return 'URL("/good-card.svg")';
    default:
      break;
  }
};

export const determineColor = (grade) => {
  switch (grade) {
    case 'low':
      return '#fa6161';
    case 'medium':
      return '#f6976f';
    case 'high':
      return '#5e826b';
    default:
      break;
  }
};

export const determineScore = (grade) => {
  switch (grade) {
    case 'low':
      return 25;
    case 'medium':
      return 50;
    case 'high':
      return 75;
    default:
      break;
  }
};

export const showGA4UserId = () => {
  if (!window.localStorage.getItem('user_fairatmos')) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer[1] = {
      user_id: '0',
    };
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer[1] = {
      user_id: `${
        JSON.parse(window.localStorage.getItem('user_fairatmos')).userId
      }`,
    };
  }
};

export const computeCarbonCreditLegend = (summary) => {
  // CARBON CREDIT CHART
  // Perlindungan hutan
  const aggresiveCarbonCreditPH =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Forest Protection') ||
        el.label?.includes('Perlindungan Hutan'),
    );
  const conserveCarbonCreditPH =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Forest Protection') ||
        el.label?.includes('Perlindungan Hutan'),
    );
  // Reforestasi
  const aggresiveCarbonCreditRnR =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi'),
    );
  const conserveCarbonCreditRnR =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi'),
    );
  // Mangrove Reforestasi
  const aggresiveCarbonCreditMR =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Mangrove Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi Mangrove'),
    );
  const conserveCarbonCreditMR =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Mangrove Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi Mangrove'),
    );
  // IFM
  const aggresiveCarbonCreditIFM =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('IFM'),
    );
  const conserveCarbonCreditIFM =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('IFM'),
    );
  // AUDD
  const aggresiveCarbonCreditAUDD =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('AUDD'),
    );
  const conserveCarbonCreditAUDD =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('AUDD'),
    );
  // APD
  const aggresiveCarbonCreditAPD =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('APD'),
    );
  const conserveCarbonCreditAPD =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('APD'),
    );
  // ARR
  const aggresiveCarbonCreditARR =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('ARR'),
    );
  const conserveCarbonCreditARR =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('ARR'),
    );
  // RWE
  const aggresiveCarbonCreditRWE =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('RWE'),
    );
  const conserveCarbonCreditRWE =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('RWE'),
    );
  // TWR
  const aggresiveCarbonCreditTWR =
    summary?.reports?.carbon_credit_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('TWR'),
    );
  const conserveCarbonCreditTWR =
    summary?.reports?.carbon_credit_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('TWR'),
    );

  const carbonCreditLegend = [
    {
      color: aggresiveCarbonCreditPH?.color,
      text: aggresiveCarbonCreditPH?.label,
      isExistAggresive: aggresiveCarbonCreditPH,
      isExistConservative: conserveCarbonCreditPH,
    },
    {
      color: aggresiveCarbonCreditRnR?.color,
      text: aggresiveCarbonCreditRnR?.label,
      isExistAggresive: aggresiveCarbonCreditRnR,
      isExistConservative: conserveCarbonCreditRnR,
    },
    {
      color: aggresiveCarbonCreditMR?.color,
      text: aggresiveCarbonCreditMR?.label,
      isExistAggresive: aggresiveCarbonCreditMR,
      isExistConservative: conserveCarbonCreditMR,
    },
    {
      color: aggresiveCarbonCreditIFM?.color,
      text: aggresiveCarbonCreditIFM?.label,
      isExistAggresive: aggresiveCarbonCreditIFM,
      isExistConservative: conserveCarbonCreditIFM,
    },
    {
      color: aggresiveCarbonCreditAUDD?.color,
      text: aggresiveCarbonCreditAUDD?.label,
      isExistAggresive: aggresiveCarbonCreditAUDD,
      isExistConservative: conserveCarbonCreditAUDD,
    },
    {
      color: aggresiveCarbonCreditAPD?.color,
      text: aggresiveCarbonCreditAPD?.label,
      isExistAggresive: aggresiveCarbonCreditAPD,
      isExistConservative: conserveCarbonCreditAPD,
    },
    {
      color: aggresiveCarbonCreditARR?.color,
      text: aggresiveCarbonCreditARR?.label,
      isExistAggresive: aggresiveCarbonCreditARR,
      isExistConservative: conserveCarbonCreditARR,
    },
    {
      color: aggresiveCarbonCreditRWE?.color,
      text: aggresiveCarbonCreditRWE?.label,
      isExistAggresive: aggresiveCarbonCreditRWE,
      isExistConservative: conserveCarbonCreditRWE,
    },
    {
      color: aggresiveCarbonCreditTWR?.color,
      text: aggresiveCarbonCreditTWR?.label,
      isExistAggresive: aggresiveCarbonCreditTWR,
      isExistConservative: conserveCarbonCreditTWR,
    },
  ];

  return carbonCreditLegend;
};

export const computeAnnualCreditLegend = (summary) => {
  // ANNUAL REVENUE CHART
  // Perlindungan hutan
  const aggresiveAnnualRevenuePH =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Forest Protection') ||
        el.label?.includes('Perlindungan Hutan'),
    );
  const conserveAnnualRevenuePH =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Forest Protection') ||
        el.label?.includes('Perlindungan Hutan'),
    );
  // Reforestasi
  const aggresiveAnnualRevenueRnR =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi'),
    );
  const conserveAnnualRevenueRnR =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi'),
    );
  // Mangrove Reforestasi
  const aggresiveAnnualRevenueMR =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find(
      (el) =>
        el.label?.includes('Mangrove Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi Mangrove'),
    );
  const conserveAnnualRevenueMR =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find(
      (el) =>
        el.label?.includes('Mangrove Reforestation and Revegetation') ||
        el.label?.includes('Reforestasi dan Revegetasi Mangrove'),
    );
  // IFM
  const aggresiveAnnualRevenueIFM =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label.includes('IFM'),
    );
  const conserveAnnualRevenueIFM =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label.includes('IFM'),
    );
  // AUDD
  const aggresiveAnnualRevenueAUDD =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('AUDD'),
    );
  const conserveAnnualRevenueAUDD =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('AUDD'),
    );
  // APD
  const aggresiveAnnualRevenueAPD =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('APD'),
    );
  const conserveAnnualRevenueAPD =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('APD'),
    );
  // ARR
  const aggresiveAnnualRevenueARR =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('ARR'),
    );
  const conserveAnnualRevenueARR =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('ARR'),
    );
  // RWE
  const aggresiveAnnualRevenueRWE =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('RWE'),
    );
  const conserveAnnualRevenueRWE =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('RWE'),
    );
  // TWR
  const aggresiveAnnualRevenueTWR =
    summary?.reports?.annual_revenue_estimation?.aggresive?.data?.find((el) =>
      el.label?.includes('TWR'),
    );
  const conserveAnnualRevenueTWR =
    summary?.reports?.annual_revenue_estimation?.conserve?.data?.find((el) =>
      el.label?.includes('TWR'),
    );

  const annualCreditLegend = [
    {
      color: aggresiveAnnualRevenuePH?.color,
      text: aggresiveAnnualRevenuePH?.label,
      isExistAggresive: aggresiveAnnualRevenuePH,
      isExistConservative: conserveAnnualRevenuePH,
    },
    {
      color: aggresiveAnnualRevenueRnR?.color,
      text: aggresiveAnnualRevenueRnR?.label,
      isExistAggresive: aggresiveAnnualRevenueRnR,
      isExistConservative: conserveAnnualRevenueRnR,
    },
    {
      color: aggresiveAnnualRevenueMR?.color,
      text: aggresiveAnnualRevenueMR?.label,
      isExistAggresive: aggresiveAnnualRevenueMR,
      isExistConservative: conserveAnnualRevenueMR,
    },
    {
      color: aggresiveAnnualRevenueIFM?.color,
      text: aggresiveAnnualRevenueIFM?.label,
      isExistAggresive: aggresiveAnnualRevenueIFM,
      isExistConservative: conserveAnnualRevenueIFM,
    },
    {
      color: aggresiveAnnualRevenueAUDD?.color,
      text: aggresiveAnnualRevenueAUDD?.label,
      isExistAggresive: aggresiveAnnualRevenueAUDD,
      isExistConservative: conserveAnnualRevenueAUDD,
    },
    {
      color: aggresiveAnnualRevenueAPD?.color,
      text: aggresiveAnnualRevenueAPD?.label,
      isExistAggresive: aggresiveAnnualRevenueAPD,
      isExistConservative: conserveAnnualRevenueAPD,
    },
    {
      color: aggresiveAnnualRevenueARR?.color,
      text: aggresiveAnnualRevenueARR?.label,
      isExistAggresive: aggresiveAnnualRevenueARR,
      isExistConservative: conserveAnnualRevenueARR,
    },
    {
      color: aggresiveAnnualRevenueRWE?.color,
      text: aggresiveAnnualRevenueRWE?.label,
      isExistAggresive: aggresiveAnnualRevenueRWE,
      isExistConservative: conserveAnnualRevenueRWE,
    },
    {
      color: aggresiveAnnualRevenueTWR?.color,
      text: aggresiveAnnualRevenueTWR?.label,
      isExistAggresive: aggresiveAnnualRevenueTWR,
      isExistConservative: conserveAnnualRevenueTWR,
    },
  ];

  return annualCreditLegend;
};

export const isNumeric = (str) => /^[0-9]*$/.test(str);

export const handlePaste = (event, type = 'text') => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData('text');

  if (type === 'text') {
    if (forbiddenCharacters.test(pastedText)) {
      event.preventDefault();
    }
  } else if (type === 'number') {
    if (!isNumeric(pastedText)) {
      event.preventDefault();
    }
  }
};

export const PhoneNumberPattern = /^(\+|\d)[0-9]{5,12}$/;

export const PasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const forbiddenCharacters = /[$/<>|*#^]/;

export const FloatPattern = /^(?=.*[1-9])\d*(?:\.\d*)?$/;
