import { createSlice } from '@reduxjs/toolkit';

const initialStateCreateProject = {
  loader: false,
  isSuccess: null,
  pagination: {},
  categoryList: [],
  categoryListData: false,
  routeOtherActivity: null,
  categoryListPayload: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    pageInfo: null,
  },
  projectBusinessList: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    pageInfo: null,
  },
  getPageTwo: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    pageInfo: null,
  },
  QuestionDetailPayload: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    selected: '',
    title: '',
    message: '',
    pageInfo: null,
  },
  getTemplateLayout: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    selected: '',
    idProject: null,
    title: '',
    message: '',
    pageInfo: null,
  },
  template: null,
  postTemplate: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    isJarvisError: false,
    isOTPError: false,
    data: null,
    animation: '',
    selected: '',
    title: '',
    message: '',
    pageInfo: null,
  },
  postEmailError: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
  },
  OngoingProject: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  provinces: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  cities: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  district: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  OTPPhone: {
    isLoading: false,
    success: false,
    failed: false,
    errorMsg: null,
  },
  resendOTPState: {
    isLoading: false,
    success: false,
    failed: false,
    errorMsg: null,
  },
  SelectedOptionPayload: null,
  phoneNumber: null,
  projectId: null,
  showModalShapefileInfo: false,
};

const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: initialStateCreateProject,
  reducers: {
    setLoading(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: actions.payload.status,
        data: null,
      };
    },
    resetResponse(state, action) {
      state.isSuccess = action.payload || null;
    },
    setRouteOtherActivity(state, action) {
      state.routeOtherActivity = action.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
    getCategorySectorListSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    },
    getCategorySectorListFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    getCategorySectorList(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isFetch: true,
      };
    },
    getQuestionDetailSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    },
    getQuestionDetailFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    getQuestionDetail(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        selected: '',
        title: '',
        animation: '',
        message: '',
        isLoading: true,
        isFetch: true,
      };
    },
    getProvinces(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getProvincesSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getProvincesFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getCitiesByProvince(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getCitiesByProvinceSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getCitiesByProvinceFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getDistrictByCity(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getDistrictByCitySuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getDistrictByCityFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getOngoingProjectSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getOngoingProjectFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getOngoingProject(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isSuccess: false,
        isError: false,
        data: null,
        isFetch: true,
      };
    },
    postSelectedOptionSuccess(state, action) {
      state.SelectedOptionPayload = action.payload.SelectedOptionPayload;
    },
    postSelectedOptionFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    postSelectedOption(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isFetch: true,
      };
    },
    setCategorySectorList(state, action) {
      state.categoryListPayload = action.payload;
      state.isSuccess = null;
    },
    resetCategorySectorList(state, action) {
      state.categoryListPayload = action.payload;
      state.isSuccess = null;
    },
    resetCitiesAndDitrict(state) {
      state.cities.data = [];
      state.district.data = [];
    },

    // GET TEMPLATE EVERY PAGE
    getTemplateEveryPage(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isLoading: true,
          isFetch: true,
        },
      };
    },
    getTemplateEveryPageSuccess(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.projectId = actions.payload.projectId;
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isSuccess: true,
          isLoading: false,
          isFetch: true,
          ...actions.payload.response,
        },
      };
    },
    getTemplateEveryPageFailed(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          isFetch: true,
        },
      };
    },
    // POST TEMPLATE EVERY PAGE
    postTemplateEveryPage(state) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: true,
        isError: false,
        isFetch: true,
        data: null,
      };
    },
    postTemplateEveryPageSuccess(state, actions) {
      state.postTemplate = {
        isSuccess: true,
        isLoading: false,
        isError: false,
        isFetch: true,
        data: actions.payload.data,
        pageInfo: null,
      };
    },
    postTemplateEveryPageFailed(state, actions) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: false,
        isError: true,
        isFetch: true,
        isJarvisError: actions.payload.isJarvisError,
        isOTPError: actions.payload.isOTPError,
        data: null,
        message: actions.payload.data,
        pageInfo: null,
      };
    },
    postTemplateEveryPageReset(state) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: false,
        isError: false,
        isFetch: false,
        data: null,
      };
    },
    // POST SEND EMAIL ERROR
    postSendEmailError(state) {
      state.postEmailError = {
        isSuccess: false,
        isLoading: true,
        isError: false,
        isFetch: true,
      };
    },
    postSendEmailErrorSuccess(state) {
      state.postEmailError = {
        isSuccess: true,
        isLoading: false,
        isError: false,
        isFetch: true,
      };
    },
    postSendEmailErrorFailed(state, actions) {
      state.postEmailError = {
        isSuccess: false,
        isLoading: false,
        isError: true,
        isFetch: true,
      };
      state.postTemplate = {
        isOTPError: actions.payload.isOTPError,
        isFetch: true,
      };
    },
    postSendEmailErrorReset(state) {
      state.postEmailError = {
        isSuccess: false,
        isLoading: false,
        isError: false,
        isFetch: false,
      };
    },
    getProjectListBusiness(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isFetch: true,
      };
    },
    getProjectListBusinessSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    },
    getProjectListBusinessFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    postOTPPhone(state, action) {},
    postOTPPhoneLoading(state) {
      state.OTPPhone = {
        isLoading: true,
        success: false,
        failed: false,
        errorMsg: null,
      };
    },
    postOTPPhoneSuccess(state, actions) {
      state.OTPPhone = {
        isLoading: false,
        success: true,
        failed: false,
        errorMsg: null,
      };
      state.resendOTPState = {
        isLoading: false,
        success: false,
        failed: false,
        errorMsg: null,
      };
      state.postTemplate = {
        isSuccess: true,
        isLoading: false,
        isError: false,
        isFetch: true,
        data: actions.payload.data && actions.payload.data,
        pageInfo: null,
      };
    },
    postOTPPhoneFailure(state, action) {
      state.OTPPhone = {
        isLoading: false,
        success: false,
        failed: true,
        errorMsg: action.payload && action.payload,
      };
      state.resendOTPState = {
        isLoading: false,
        success: false,
        failed: false,
        errorMsg: null,
      };
    },
    resendOTPPhone(state, action) {},
    resendOTPPhoneLoading(state, action) {
      state.resendOTPState = {
        isLoading: true,
        success: false,
        failed: false,
        errorMsg: null,
      };
    },
    resendOTPPhoneSuccess(state) {
      state.resendOTPState = {
        isLoading: false,
        success: true,
        failed: false,
        errorMsg: null,
      };
    },
    resendOTPPhoneFailure(state, action) {
      state.resendOTPState = {
        isLoading: false,
        success: false,
        failed: true,
        errorMsg: action.payload,
      };
    },
    resetOTPPhone(state) {
      state.OTPPhone = {
        errorMsg: null,
      };
      state.resendOTPState = {
        isLoading: false,
        success: false,
        failed: false,
        errorMsg: null,
      };
    },
    setShowModalShapefileInfo(state, actions) {
      state.showModalShapefileInfo = actions.payload;
    },
  },
});

export const {
  getCategorySectorList,
  setLoading,

  setRouteOtherActivity,

  setCategorySectorList,
  getCategorySectorListSuccess,
  getCategorySectorListFailed,

  getQuestionDetail,
  getQuestionDetailSuccess,
  getQuestionDetailFailed,

  getProjectListBusiness,
  getProjectListBusinessSuccess,
  getProjectListBusinessFailed,

  getOngoingProject,
  getOngoingProjectSuccess,
  getOngoingProjectFailed,

  getProvinces,
  getProvincesSuccess,
  getProvincesFailed,

  getCitiesByProvince,
  getCitiesByProvinceSuccess,
  getCitiesByProvinceFailed,

  getDistrictByCity,
  getDistrictByCitySuccess,
  getDistrictByCityFailed,

  postSelectedOption,
  postSelectedOptionSuccess,
  postSelectedOptionFailed,

  //GET TEMPLATE EVERY PAGE
  getTemplateEveryPage,
  getTemplateEveryPageSuccess,
  getTemplateEveryPageFailed,

  //POST TEMPLATE EVERT PAGE
  postTemplateEveryPage,
  postTemplateEveryPageSuccess,
  postTemplateEveryPageFailed,
  postTemplateEveryPageReset,

  postSendEmailError,
  postSendEmailErrorSuccess,
  postSendEmailErrorFailed,
  postSendEmailErrorReset,

  resetCitiesAndDitrict,

  postOTPPhone,
  postOTPPhoneLoading,
  postOTPPhoneSuccess,
  postOTPPhoneFailure,

  resendOTPPhone,
  resendOTPPhoneLoading,
  resendOTPPhoneSuccess,
  resendOTPPhoneFailure,

  resetOTPPhone,

  setShowModalShapefileInfo,
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
