import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectListLoading: true,
  projectList: [],
  projectListStatus: null,
  projectEmpty: false,
  pageValue: 1,
  PricingDataStatus: null,
  isManagableData: null,
  isManagableBySearch: null,
  unlockStatus: null,
  lockStatus: null,
  loadProject: false,
  selectedIdProject: [],
  showModalVerify: false,
  refetchProject: false,
  showModalDeleteProject: false,
  showModalFilterProject: false,
  queryFilter: '',
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectListLoading(state, action) {
      state.projectListLoading = action.payload.projectListLoading;
      state.projectList = [];
    },
    projectList(state) {
      state.formLoading = true;
      state.projectReferenceStatus = null;
    },
    projectListEmpty(state, action) {
      state.formLoading = false;
      state.projectEmpty = action.payload.projectEmpty;
      state.projectListStatus = 'Success';
    },
    projectListSuccess(state, action) {
      state.formLoading = false;
      state.projectList = action.payload.projectList;
      state.projectEmpty = action.payload.projectEmpty;
      state.projectListStatus = 'Success';
      state.isManagableData = action.payload.isManagableData;
    },
    projectListFailed(state, action) {
      state.formLoading = false;
      state.projectListStatus = 'Fail';
    },
    getManageable(state) {},
    getManageableLoading(state) {},
    getManageableSuccess(state, action) {
      state.isManagableBySearch = action.payload.isManagableData;
    },
    unlockAccount(state) {},
    unlockAccountLoading(state) {
      state.unlockStatus = null;
    },
    unlockAccountSuccess(state) {
      state.unlockStatus = true;
    },
    unlockAccountFailed(state) {
      state.unlockStatus = false;
    },
    unlockAccountReset(state) {
      state.unlockStatus = null;
    },
    deleteProject(state) {},
    lockAccount(state) {},
    lockAccountLoading(state) {
      state.lockStatus = null;
    },
    lockAccountSuccess(state) {
      state.lockStatus = true;
    },
    lockAccountFailed(state) {
      state.lockStatus = false;
    },
    lockAccountReset(state) {
      state.lockStatus = null;
    },
    setPageValue(state, actions) {
      state.pageValue = actions.payload.page;
    },
    setLoadProject(state, actions) {
      state.loadProject = actions.payload;
    },
    setSelectedIdProject(state, actions) {
      state.selectedIdProject = actions.payload;
    },
    setShowModalVerify(state, actions) {
      state.showModalVerify = actions.payload;
    },
    setShowModalDeleteProject(state, actions) {
      state.showModalDeleteProject = actions.payload;
    },
    setShowModalFilterProject(state, actions) {
      state.showModalFilterProject = actions.payload;
    },
    setRefetchProject(state, actions) {
      state.refetchProject = actions.payload;
    },
    setQueryFilter(state, actions) {
      state.queryFilter = actions.payload;
    },
  },
});

export const {
  projectListLoading,
  projectList,
  projectListEmpty,
  projectListSuccess,
  projectListFailed,
  getManageable,
  getManageableLoading,
  getManageableSuccess,
  setPageValue,
  unlockAccount,
  unlockAccountLoading,
  unlockAccountSuccess,
  unlockAccountFailed,
  unlockAccountReset,
  deleteProject,
  lockAccount,
  lockAccountLoading,
  lockAccountSuccess,
  lockAccountFailed,
  lockAccountReset,
  setLoadProject,
  setSelectedIdProject,
  setShowModalVerify,
  setShowModalDeleteProject,
  setShowModalFilterProject,
  setRefetchProject,
  setQueryFilter,
} = projectSlice.actions;
export default projectSlice.reducer;
