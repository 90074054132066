import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectReferenceLoading: true,
  projectReference: null,
  projectReferenceStatus: null,
  resultReady: false,
  landCoverActive: 'Eligible',
  vcuData: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    data: null,
  },
  vcuResultData: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    data: null,
  },
  vcuLinkCSV: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    link: null,
  },
  upgradeToPremium: null,
  contactDataStatus: null,
  linkMap1: null,
  mapLegend1: null,
  statusLinkMap1: false,
  linkMap2: null,
  mapLegend2: null,
  statusLinkMap2: false,
  isOldProject: false,
  isDataIssue: false,
  statusInternalMap: false,
  internalMap: null,
  criteria: 'finances',
  showMapInternalModal: false,
  showModalEstimation: false,
  showModalOldProject: false,
  showModalDataIssue: false,
  showModalContact: false,
  isLocationVisible: false,
};

const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    waitMapReady(state) {},
    waitMapReadyLoading(state) {},
    waitMapReadySuccess(state, action) {
      state.statusLinkMap1 = action.payload.statusLinkMap1;
      state.linkMap1 = action.payload.linkMap1;
      state.mapLegend1 = action.payload.mapLegend1;
      state.statusLinkMap2 = action.payload.statusLinkMap2;
      state.linkMap2 = action.payload.linkMap2;
      state.mapLegend2 = action.payload.mapLegend2;
      state.statusInternalMap = action.payload.statusInternalMap;
      state.internalMap = action.payload.internalMap;
    },
    projectReference(state) {
      state.projectReferenceLoading = true;
      state.showMapInternalModal = false;
      state.showModalEstimation = false;
      state.showModalContact = false;
      state.projectReference = null;
    },
    projectReferenceSuccess(state, action) {
      state.projectReferenceLoading = false;
      state.resultReady = true;
      state.projectReference = action.payload.result;
      state.linkMap1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_user
          : null;
      state.statusLinkMap1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_ready
          : null;
      state.mapLegend1 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[0]?.map_legend
          : null;
      state.linkMap2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_user
          : null;
      state.statusLinkMap2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_ready
          : null;
      state.mapLegend2 =
        typeof action.payload.result.summary?.reports?.project_area === 'object'
          ? action.payload.result.summary?.reports?.project_area[1]?.map_legend
          : null;
      state.statusInternalMap =
        action.payload.isManagableData === true &&
        typeof action.payload.result?.summary?.additional_data?.maps ===
          'object'
          ? action.payload.result?.summary?.additional_data?.maps[0]?.map_ready
          : false;
      state.internalMap =
        action.payload.isManagableData === true &&
        typeof action.payload.result?.summary?.additional_data?.maps ===
          'object'
          ? action.payload.result?.summary?.additional_data?.maps[0]?.map_user
          : null;
      state.projectReferenceStatus = 'Success';
      state.isManagableData = action.payload.isManagableData;
      state.isOldProject = action.payload.isOldProject;
      state.isDataIssue = action.payload.isDataIssue;
    },
    projectReferenceFailed(state) {
      state.projectReferenceLoading = false;
      state.projectReferenceStatus = 'Fail';
    },
    projectReferenceReset(state) {
      state.projectReferenceLoading = true;
      state.projectReferenceStatus = null;
    },
    setContactDataLoading() {},
    setContactData(state) {
      state.contactDataStatus = null;
    },
    setContactDataSuccess(state) {
      state.contactDataStatus = 'Success';
    },
    setContactDataFailed(state) {
      state.contactDataStatus = 'Fail';
    },
    postVCUCalculationLoading(state) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: true,
      };
    },
    postVCUCalculation(state) {},
    postVCUCalculationSuccess(state, action) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: false,
        isSuccess: true,
        isFailed: false,
        data: action.payload,
      };
    },
    postVCUCalculationFailed(state) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: false,
        isSuccess: false,
        isFailed: true,
        data: null,
      };
    },
    postVCUCalculationReset(state) {
      state.vcuResultData = {
        ...state.vcuResultData,
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        data: null,
      };
    },
    getResultVCULoading(state) {
      state.vcuResultData = {
        ...state.vcuResultData,
        isLoading: true,
      };
    },
    getResultVCU(state) {},
    getResultVCUSuccess(state, action) {
      state.vcuResultData = {
        ...state.vcuResultData,
        isLoading: false,
        isSuccess: true,
        isFailed: false,
        data: action.payload.data,
        statusCode: action.payload.statusCode,
      };
    },
    getResultVCUFailed(state) {
      state.vcuResultData = {
        ...state.vcuResultData,
        isLoading: false,
        isSuccess: false,
        isFailed: true,
        data: null,
        statusCode: null,
      };
    },
    getInputVCULoading(state) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: true,
      };
    },
    getInputVCU(state) {},
    getInputVCUSuccess(state, action) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: false,
        isSuccess: true,
        isFailed: false,
        data: action.payload,
      };
    },
    getInputVCUFailed(state) {
      state.vcuData = {
        ...state.vcuData,
        isLoading: false,
        isSuccess: false,
        isFailed: true,
        data: null,
      };
    },
    getLinkDownloadCSVLoading(state) {
      state.vcuLinkCSV = {
        ...state.vcuLinkCSV,
        isLoading: true,
        isSuccess: false,
        isFailed: false,
        link: null,
      };
    },
    getLinkDownloadCSV(state) {},
    getLinkDownloadCSVSuccess(state, action) {
      state.vcuLinkCSV = {
        ...state.vcuLinkCSV,
        isLoading: false,
        isSuccess: true,
        isFailed: false,
        link: action.payload,
      };
    },
    getLinkDownloadCSVFailed(state) {
      state.vcuLinkCSV = {
        ...state.vcuLinkCSV,
        isLoading: false,
        isSuccess: false,
        isFailed: true,
        link: null,
      };
    },
    getLinkDownloadCSVReset(state) {
      state.vcuLinkCSV = {
        ...state.vcuLinkCSV,
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        link: null,
      };
    },
    changeProjectStatus(state) {},
    changeResultReady(state, action) {
      state.resultReady = action.payload.resultReady;
      state.projectReferenceLoading = action.payload.loading;
      state.projectReference = null;
    },
    resetModalOldProject(state) {
      state.isOldProject = false;
      state.showModalOldProject = false;
    },
    resetModalDataIssue(state) {
      state.isDataIssue = false;
      state.showModalDataIssue = false;
    },
    setCriteria(state, action) {
      state.criteria = action.payload;
    },
    setShowMapInternalModal(state, action) {
      state.showMapInternalModal = action.payload;
    },
    setLandCoverActive(state, action) {
      state.landCoverActive = action.payload;
    },
    setShowModalEstimation(state, action) {
      state.showModalEstimation = action.payload;
    },
    setShowModalOldProject(state, action) {
      state.showModalOldProject = action.payload;
    },
    setShowModalDataIssue(state, action) {
      state.showModalDataIssue = action.payload;
    },
    setShowModalContact(state, action) {
      state.showModalContact = action.payload;
    },
    setIsLocationVisible(state, action) {
      state.isLocationVisible = action.payload;
    },
    trackDownloadPDF(state) {},
  },
});

export const {
  waitMapReady,
  waitMapReadyLoading,
  waitMapReadySuccess,
  projectReference,
  projectReferenceSuccess,
  projectReferenceFailed,
  projectReferenceReset,
  changeResultReady,
  setContactData,
  setContactDataLoading,
  setContactDataSuccess,
  setContactDataFailed,
  getLinkDownloadCSV,
  getLinkDownloadCSVLoading,
  getLinkDownloadCSVSuccess,
  getLinkDownloadCSVFailed,
  getLinkDownloadCSVReset,
  postVCUCalculation,
  postVCUCalculationLoading,
  postVCUCalculationSuccess,
  postVCUCalculationFailed,
  postVCUCalculationReset,
  getResultVCU,
  getResultVCULoading,
  getResultVCUSuccess,
  getResultVCUFailed,
  getInputVCU,
  getInputVCULoading,
  getInputVCUSuccess,
  getInputVCUFailed,
  resetModalOldProject,
  resetModalDataIssue,
  changeProjectStatus,
  setCriteria,
  setShowMapInternalModal,
  setLandCoverActive,
  setShowModalEstimation,
  setShowModalOldProject,
  setShowModalDataIssue,
  setShowModalContact,
  setIsLocationVisible,
  trackDownloadPDF,
} = resultSlice.actions;
export default resultSlice.reducer;
